<template>
	<div>
		<search-header></search-header>
		<div class="aw-container-wrap">
			<div v-if="classList.length > 0" class="container category border mt-3 mb-3">
				<div class="row p-3 pt-4">
					<div class="col-12">
						<ul class="list">
							<li @click="onClassClick('',0)" :class="{'active' : para.param.classId == ''}">
								<a href="javascript:;">全部</a>
							</li>
							<li @click="onClassClick(m.code,0)" :class="{'active' : para.param.classId == m.code}" v-for="(m,i) in classList" :key="i">
								<a href="javascript:;">{{m.name}}</a>
							</li>
						</ul>
					</div>
					<div class="col-12" style="padding-left:74px">
						<ul v-if="getChildClassList.length > 0" class="list">
							<li @click="onClassClick(c.code,1)" :class="{'active' : para.param.childClassId == c.code}" v-for="(c,j) in getChildClassList" :key="j">
								<a href="javascript:;">{{c.name}}</a>
							</li>
						</ul>
					</div>
				</div>
			</div>
			<div class="container mb-3">
				<div class="row border rounded">
					<div class="col-sm-12 col-md-9 p-0 border-right aw-main-content">
						<nav class="navbar navbar-expand-lg navbar-light bg-light">
							<div class="collapse navbar-collapse" id="navbarSupportedContent">
								<ul class="navbar-nav mr-auto">
									<li class="nav-item" :class="{'active' : m.value == para.param.questionStatus}" v-for="m,i in status" :key="i">
										<a class="nav-link" @click="onStatusClick(m.value)" href="javascript:;">{{m.name}}</a>
									</li>
								</ul>
								<form class="form-inline my-2 my-lg-0">
									<input v-model="para.param.title" class="form-control mr-sm-2" type="search" placeholder="搜索您想知道的问题">
									<b-button variant="outline-success" @click="init" class="mr-2">搜索</b-button>
									<router-link class="btn btn-success my-2 my-sm-0" to="/ask/add"  target="_blank">我要提问</router-link>
								</form>
							</div>
						</nav>
						<div class="aw-mod mr-3 ml-3">
							<div class="mod-body">
								<div class="aw-common-list">
									<div class="aw-item" v-for="m,i in list.records" :key="i">
										<div class="aw-user-name">
											<img v-if="m.headPic != null && m.headPic != ''" class="aw-user-img rounded" :src="m.headPic">
											<img v-else class="aw-user-img rounded" src="@/assets/img/headpic.png">
										</div>
										<div class="aw-question-content">
											<h4>
												<router-link :to="'/ask/detail?id=' + m.id" target="_blank">{{m.nicName ? m.nicName : '游客'}}: {{m.title}}</router-link>
											</h4>
											<div v-if="m.adoptUser" class="float-right contribute">
												<span class="float-right text-gray-777 f-12">采纳</span>
												<div class="aw-user-name">
													<img v-if="m.adoptUser.headPic != null && m.adoptUser.headPic != ''" class="aw-user-img rounded-circle" :src="m.adoptUser.headPic">
													<img v-else class="aw-user-img rounded-circle" src="@/assets/img/headpic.png">
												</div>
											</div>
											<p>
												<span v-if="getTagValue(m.classId,m.childClassId)" class="aw-question-tags text-success"><b-icon icon="tag" variant="success"></b-icon> {{getTagValue(m.classId,m.childClassId)}}</span>
												<span> • {{m.answerNum}} 人回答 • {{m.adoptAnswerId ? '已解决' : '待解决'}} • {{m.createdAt}}</span>
											</p>
											<div v-if="m.latestAnswerContent" class="clearfix mt-2 f-14">
												<img v-if="m.latestAnswerIsOfficial > 0" class="float-left mr-1 width-20" src="~@/assets/icon/official.png"/>
												<div class="float-left">
													<span class="text-gray-777">{{m.latestAnswerIsAnonymous > 0 ?  '匿名' : (m.latestAnswerUserNicName == null ? '游客': m.latestAnswerUserNicName) }}:</span>
													<span class="text-gray-777" v-html="m.latestAnswerContent.replace(/<[^>]*>/g,'')"></span>
													<p class="p-0">
														<span>• 最新回答 • {{m.latestAnswerDate}} </span>
													</p>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
							<div v-if="list && list.total > list.size" class="mb-3 mt-3">
								<div class="clearfix text-center">
									<div class="float-right ml-2">
										<input v-model="goPageValue" class="page-input"> 页
										<input @click="onGoPage" type="submit" class="page-jump"
											value="跳转">
									</div>
									<b-pagination @change="onPageChange" class="float-right" :total-rows="list.total" :per-page="list.size" v-model="para.current"></b-pagination>
								</div>
							</div>
						</div>
					</div>

					<!-- 侧边栏 -->
					<div class="col-sm-12 col-md-3 aw-side-bar">
						<div class="aw-mod">
							<div class="mod-head mt-3">
								<h3 class="f-16">热门问题</h3>
							</div>
							<div class="clearfix mt-2" v-for="m,i in hotQuestionList" :key="i">
								<div class="float-left">
									<img v-if="m.headPic != null && m.headPic != ''" class="rounded width-40" :src="m.headPic">
									<img v-else class="rounded-circle width-40" src="@/assets/img/headpic.png">
								</div>
								<div class="float-left pl-2" style="width: 200px;line-height: 1.3;">
									<router-link :to="'/ask/detail?id=' + m.id" target="_blank" class="f-14">
										{{m.nicName}}: {{m.title}}
									</router-link>
									<div class="f-14 text-black-50 text-right"><b>{{m.answerNum}}</b> 人回答</div>
								</div>
							</div>
						</div>
						<div class="aw-mod">
							<div class="mod-head">
								<h3 class="f-16">热门用户</h3>
							</div>
							<div class="clearfix mt-3" v-for="m,i in hotPeopleList" :key="i">
								<div class="float-left">
									<img v-if="m.headPic != null && m.headPic != ''" class="rounded-circle width-40" :src="m.headPic">
									<img v-else class="rounded-circle width-40" src="@/assets/img/headpic.png">
								</div>
								<div class="float-left pl-2" style="width: 200px;line-height: 1.3;">
									<span class="f-14">{{m.nicName}}</span>
									<div class="f-14 text-black-50"><b>{{m.questionNumber}}</b> 个问题, <b>{{m.answerNum}}</b> 次回答</div>
								</div>
							</div>
						</div>
					</div>
					<!-- end 侧边栏 -->
				</div>
			</div>
		</div>
		<map-footer></map-footer>
	</div>
</template>

<script>
export default {
		data() {
			return {
				classList: [],
				list: [],
				hotPeopleList: [],
				hotQuestionList: [],
				status: [{
					name: '全部问题',
					value: ''
				},{
					name: '待解决',
					value: '1'
				},{
					name: '已解决',
					value: '0'
				},{
					name: '0回答',
					value: '2'
				}],
				para: {
					param: {
						title: '',
						classId: '',
						childClassId: '',
						questionStatus: ''
					},
					size: 10,
					current: 1
				},
				goPageValue: ''
			}
		},
		computed: {
			getChildClassList () {
				if(this.para.param.classId > 0) {
					let m = this.classList.find(x=> x.code == this.para.param.classId);
					return m ? m.children : [];
				}
				return []
			},
			getTagValue() {
				return function(code,childcode) {
					let m = this.classList.find(x=> x.code == code);
					if(m) {
						let c = m.children.find(x=> x.code == childcode);
						if(c) {
							return c.name
						} else {
							return m.name
						}
					}
					return ''
				}
			}
		},
		methods: {
			onClassClick(code,type) {
				if(type == 0) {
					this.para.param.classId = code;
					this.para.param.childClassId = '';
				} else {
					this.para.param.childClassId = code;
				}
				this.init();
			},
			onStatusClick(value) {
				this.para.param.questionStatus = value;
				this.init();
			},
			onPageChange(i) {
				this.para.current = i;
				this.getList();
			},
			onGoPage() {
				if(this.$test.digits(this.goPageValue) && this.goPageValue > 0 && this.goPageValue <= Math.ceil(this.list.total / this.list.size))
				{
					this.para.current = this.goPageValue;
					this.getList();
				}
			},
			init() {
				this.para.current = 1;
				this.getList();
			},
			getClass() {
				let _this = this;
				this.$http.post(this.$api.ask.getClass,{searchParam:''}).then(res => {
					this.classList = res.result;
					let code = _this.$route.query.id;
					this.classList.forEach(x => {
						if(x.code == code) {
							_this.para.param.classId = code;
						} else {
						   let c = x.children.find(y=> y.code == code);
						   if(c) {
							   _this.para.param.childClassId = code;
							   _this.para.param.classId = c.parentCode;
						   }
						}
					});
					this.getList();
				})
			},
			getList() {
				this.$http.post(this.$api.ask.getQuestionList, this.para).then(res => {
					if(res.code == 1) {
						this.list = res.result;
					}
				})
			},
			getHotQestion() {
				this.$http.post(this.$api.ask.getHotQuestion, {top: 5}).then(res => {
					this.hotQuestionList = res.result;
				})
			},
			getHotPeople() {
				this.$http.post(this.$api.ask.getHotPeople, {top: 5}).then(res => {
					this.hotPeopleList = res.result;
				})
			}
		},
		created() {
			this.getClass();
			this.getHotQestion();
			this.getHotPeople();
		}
	}
</script>

<style scoped>
	@import url("~@/assets/css/ask.css");
</style>
